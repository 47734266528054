import { css, Global, useTheme } from '@emotion/react'
import { graphql, navigate } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'
import React, {
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react'
import { useTranslation } from 'react-i18next'
import {
  FaRegEnvelope,
  FaWhatsapp,
  FaTelegramPlane,
  FaTelegram,
  FaFacebookF,
  FaInstagram,
  FaLinkedin,
  FaGithub,
  FaYoutube,
  FaMapMarkerAlt,
} from 'react-icons/fa'
import '@fontsource/source-sans-3'
import { contained, maxWidth } from 'src/themes'
import { darken, lighten } from 'polished'
import Header from './Header'
import emotionNormalize from 'emotion-normalize'
import 'animate.css'
import { SkipNavLink, SkipNavContent } from '@reach/skip-nav'
import { stripIndent } from 'common-tags'
import '@reach/skip-nav/styles.css' //this will auto show and hide the link on focus
import { Link } from 'gatsby-plugin-react-i18next'
import { ContactForm } from './ContactForm'
import { globalHistory } from '@reach/router'

export const FormContext = createContext({
  showForm: (
    zohoCode: string,
    courseCode: string,
    courseName: string,
    type: 'request_syllabus' | 'apply_now'
  ) => { },
  hideForm: () => { },
})

export default function Layout({ children }: PropsWithChildren<{}>) {
  const { t } = useTranslation()
  const theme = useTheme()

  const [formInfo, setFormInfo] = useState<{
    zohoCode: string
    courseCode: string
    courseName: string
    type: 'request_syllabus' | 'apply_now'
  }>({
    zohoCode: '',
    courseCode: '',
    courseName: '',
    type: 'request_syllabus',
  })
  const [show, setShow] = useState(false)

  useEffect(() => {
    return globalHistory.listen((props) => {
      if (props.action === 'POP') {
        setShow(false)
      }
    })
  }, [setShow])

  const showForm = useCallback(
    (
      zohoCode: string,
      courseCode: string,
      courseName: string,
      type: 'request_syllabus' | 'apply_now'
    ) => {
      navigate('#form')
      setFormInfo({ zohoCode, courseCode, courseName, type })
      setShow(true)
    },
    [setShow, setFormInfo]
  )

  const hideForm = useCallback(() => {
    console.log('hidden')
    navigate(-1)
    setShow(false)
  }, [setShow])

  return (
    <div
      css={css`
				overflow-x: clip;
			`}>
      <Global
        styles={css`
					${emotionNormalize}

					:root {
						--animate-duration: 300ms;
					}
					.animate__animated.animate__flip {
						--animate-duration: 1s;
					}
					html {
						font-size: 18px;
					}
					body {
						background: ${theme.background} fixed;
						color: ${theme.textColor};
						font-family: 'Source Sans Pro', sans-serif;
						min-height: 100vh;
						line-height: 1.8rem;
						margin: 0;
						padding: 0;
						/* background-size: 400% 400%;
						animation: gradient-animation 40s linear infinite
							alternate-reverse; */
					}

					@keyframes gradient-animation {
						0% {
							background-position: 0% 50%;
						}
						100% {
							background-position: 100% 50%;
						}
					}

					[data-reach-skip-nav-link] {
						color: ${theme.inverseColor};
					}
					[data-reach-skip-nav-link]:focus {
						z-index: 2000;
					}
					${maxWidth[2]} {
						body::before {
							content: ' ';
							position: fixed;
							z-index: -1;
							top: 0;
							right: 0;
							bottom: 0;
							left: 0;
							background: ${theme.background};
							/* background-size: 400% 400%;
							animation: gradient-animation 25s linear infinite
								alternate; */
						}
					}
					a {
						color: ${theme.linkColor};
						text-decoration: none;

						&:hover {
							color: ${darken(0.1, theme.linkColor)};
							text-decoration: underline;
						}
					}
					h1 {
						text-align: center;
						font-size: 3rem;
						font-weight: 700;
					}

					h1,
					h2,
					h3,
					h4,
					h5,
					h6 {
						line-height: normal;
					}

					.fi {
						background-size: contain;
						background-position: 50%;
						background-repeat: no-repeat;
						position: relative;
						display: inline-block;
						width: 1.33333333em;
						line-height: 1em;
						::before {
							content: '\\00a0';
						}
					}
					.fi-hk {
						background-image: url(/flags/hk.svg);
					}
					.fi-gb,
					.fi-uk {
						background-image: url(/flags/gb.svg);
					}
					.fi-ca {
						background-image: url(/flags/ca.svg);
					}
					.fi-sg {
						background-image: url(/flags/sg.svg);
					}
					.fi-my {
						background-image: url(/flags/my.svg);
					}
				`}
      />
      <script
        key={`ac-tracking`}
        dangerouslySetInnerHTML={{
          __html: stripIndent`
					(function(e,t,o,n,p,r,i){e.visitorGlobalObjectAlias=n;e[e.visitorGlobalObjectAlias]=e[e.visitorGlobalObjectAlias]||function(){(e[e.visitorGlobalObjectAlias].q=e[e.visitorGlobalObjectAlias].q||[]).push(arguments)};e[e.visitorGlobalObjectAlias].l=(new Date).getTime();r=t.createElement("script");r.src=o;r.async=true;i=t.getElementsByTagName("script")[0];i.parentNode.insertBefore(r,i)})(window,document,"https://diffuser-cdn.app-us1.com/diffuser/diffuser.js","vgo");
					vgo('setAccount', '610485476');
					vgo('setTrackByDefault', true);

					vgo('process');
				`,
        }}
      />
      <script
        key={`zoho-tracking`}
        dangerouslySetInnerHTML={{
          __html: stripIndent`
					var $zoho=$zoho || {};$zoho.salesiq = $zoho.salesiq || {widgetcode:"4f58ec9af014c8cf5821d5ea2407b104f557fb0d0aa941537668f6836e9f1f80449146799cf02d29f8b897d6bb3bd152", values:{},ready:function(){$zoho.salesiq.floatbutton.visible('hide');}};var d=document;s=d.createElement("script");s.type="text/javascript";s.id="zsiqscript";s.defer=true;s.src="https://salesiq.zoho.com/widget";t=d.getElementsByTagName("script")[0];t.parentNode.insertBefore(s,t);
				`,
        }}
      />
      <SkipNavLink>{t('skip_nav_link')}</SkipNavLink>
      <header>
        <Header formShown={show} />
      </header>
      <SkipNavContent />
      <main>
        <FormContext.Provider
          value={{
            showForm,
            hideForm,
          }}>
          {children}
        </FormContext.Provider>

        <ContactForm
          zohoCode={formInfo.zohoCode}
          courseCode={formInfo.courseCode}
          courseName={formInfo.courseName}
          show={show}
          type={formInfo.type}
          onClose={() => hideForm()}
        />
      </main>
      <footer>
        <div
          css={css`
						${contained}
						display: flex;
						align-items: center;
						margin-top: 2.45rem;
						margin-bottom: 2.45rem;

						${maxWidth[2]} {
							flex-direction: column;
							text-align: center;
						}
					`}>
          <div
            css={css`
							font-size: 0.75rem;

							p {
								margin: 0;

								> a {
									padding: 0 0.5rem;
								}
								> a:first-child {
									padding-left: 0;
								}
								> a:last-child {
									padding-right: 0;
								}
							}

							${maxWidth[2]} {
								order: 2;
							}
						`}>
            <p>
              <Link to='/privacy-policy/'>
                {t('index.privacy_policy')}
              </Link>
              <Link to='/site-map/'>{t('index.site_map')}</Link>
            </p>
            <p>
              <Link to='/accessibility/'>
                {t('index.accessibility_statement')}
              </Link>
            </p>
          </div>
          <div
            css={css`
							flex: 1;
							display: flex;
							flex-direction: column;

							> div {
								font-size: 0.85rem;
								flex: 1;
								display: flex;
								align-items: center;
								justify-content: center;

								> a {
									display: flex;
									align-items: center;
									margin: 0 1rem;

									i {
										display: flex;
										align-items: center;
										justify-content: center;
										width: 2rem;
										height: 2rem;
										margin-right: 0.25rem;
									}
								}
							}
						`}>
            <div css={css`
              flex-wrap:wrap;
            `}>
              <a
                href='mailto:hello@tecky.io'
                css={css`
									& i {
										color: #ff464f;
									}
									&:hover i {
										color: ${lighten(0.1, '#ff464f')};
									}
								`}>
                <i>
                  <FaRegEnvelope />
                </i>
                hello@tecky.io
              </a>
              <a
                href='https://t.me/tecky_hub'
                css={css`
									& i {
										color: #2496c4;
									}
									&:hover i {
										color: ${lighten(0.1, '#2496c4')};
									}
								`}>
                <i>
                  <FaTelegram />
                </i>
                t.me/tecky_hub
              </a>
              <a
                href='https://api.whatsapp.com/send?phone=85297256400'
                css={css`
									& i {
										color: ${lighten(0.1, '#059203')};
									}
									&:hover i {
										color: ${lighten(0.2, '#059203')};
									}
								`}>
                <i>
                  <FaWhatsapp />
                </i>
                +852 9725 6400
              </a>
            </div>
          </div>
          <div
            css={css`
							display: flex;
							justify-content: flex-end;
							align-items: center;
							font-size: 1.5rem;

							${maxWidth[1]} {
								padding: 1rem 0;
							}

							a {
								display: flex;
								justify-content: center;
								align-items: center;
								width: 2rem;
								height: 2rem;
							}
						`}>
            <a
              aria-label='Tecky Academy Facebook'
              href='https://www.facebook.com/tecky.io'>
              <FaFacebookF />
            </a>
            <a
              aria-label='Tecky Academy Instagram'
              href='https://www.instagram.com/tecky.io/'>
              <FaInstagram />
            </a>
            <a
              aria-label='Tecky Academy LinkedIn'
              href='https://www.linkedin.com/company/tecky-academy/'>
              <FaLinkedin />
            </a>
            <a
              aria-label='Tecky Academy Github'
              href='https://github.com/teckyio'>
              <FaGithub />
            </a>
            <a
              aria-label='Tecky Academy Telegram'
              href='https://t.me/tecky_hub'>
              <FaTelegramPlane />
            </a>
            <a
              aria-label='Tecky Academy Youtube Channel'
              href='https://www.youtube.com/c/TeckyAcademy'>
              <FaYoutube />
            </a>
            <a
              aria-label='Tecky Academy Google Map Location'
              href='https://maps.app.goo.gl/NhmiY17LLjRB2T9n9'>
              <FaMapMarkerAlt />
            </a>
          </div>
        </div>
        <div
          css={css`
						display: flex;
						justify-content: center;
						align-items: center;
						padding: 0 0 2rem;

						> div {
							margin: 1rem;
						}

            ${maxWidth[1]} {
              flex-wrap: wrap;
            }
					`}>
          <StaticImage
            src='../images/green_org.png'
            width={150}
            placeholder='blurred'
            alt={t('layout:green_org')}
          />
          <StaticImage
            src='../images/caring_company_logo.png'
            width={150}
            placeholder='blurred'
            alt={t('layout:caring_company')}
          />
          <StaticImage
            src='../images/tquk_approved_logo.png'
            width={150}
            placeholder='blurred'
            alt={t('layout:tquk_approved_center')}
          />
          <StaticImage
            src='../images/aws_badge.png'
            width={80}
            placeholder='blurred'
            alt={t('layout:aws_partner')}
          />
          <StaticImage
            src='../images/reimagine_education_challenge.png'
            width={150}
            placeholder='blurred'
            alt={t('layout:reimagine_education_challenge')}
          />
          <StaticImage
            src='../images/bcorp_guide_white.png'
            width={50}
            placeholder='blurred'
            alt={t('layout:bcorp')}
          />
          <StaticImage
            src='../images/w3c21AA.png'
            width={125}
            alt={t('layout:wcag_21_aa')}
          />
          <StaticImage
            src='../images/hk_web_accessibility_logo_gold.png'
            width={125}
            alt={t('layout:hk_web_accessibility_logo_gold')}
          />
        </div>
        <div
          css={css`
						${contained}
						text-align: center;
						font-size: 0.75rem;
						margin-bottom: 2.45rem;
					`}>
          &copy; {new Date().getFullYear()} Tecky Academy Limited
        </div>
      </footer>
    </div>
  )
}

export const query = graphql`
	fragment TranslationFragment on Query {
		locales: allLocale(filter: { language: { eq: $language } }) {
			edges {
				node {
					ns
					data
					language
				}
			}
		}
	}
`
